import React from "react";
import './contact.css';
const Contact = () =>{

    const onSubmit = async(event)=>{
        event.preventDefault();
        const formData = new FormData(event.target);
        //   formData.append("")
        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);

        const res = await fetch("chetanks.com",{
            method : "POST",
            headers:{
                "Content-Type":"application/json",
                Accept:"application/json"
            },
            body:json
        }).then((res)=>res.json());

        if(res.success){
            console.log("Success",res);
        }
    };

    return (
        <section className="contact">
            <form onSubmit={onSubmit} autoComplete="off">
                <h2>Contact Form</h2>
                <div className="input-box">
                    <label htmlFor="">Full Name</label>
                    <input type="text" className="field" name="name" placeholder="Enter Your Name"/>
                </div>
                <div className="input-box">
                    <label htmlFor="">Email Address</label>
                    <input type="email" className="field" name="email" placeholder="chetan@example.com"/>
                </div>
                <div className="input-box">
                    <label htmlFor="">Your Message</label>
                    <textarea name="message" id="" className="field mess" placeholder="Enter Your Message..."></textarea>
                </div>
                <button type="submit">Send Message</button>
            </form>
        </section>
    )
}

export default Contact